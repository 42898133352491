import React from "react";
import styled from "styled-components";
import { MessageDescriptor, useIntl } from "react-intl";
import themeGet from "@styled-system/theme-get";
import { Text, Box, theme } from "@cyberalarm/ui";
import Linkify from "linkify-react";

export const SectionLabel = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

export const ImageLabel = styled(Text)`
  margin: auto 16px auto 0;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    min-width: 150px;
  }
`;

export const SectionDescription = styled(Text)`
  white-space: pre-wrap;
  font-size: 16px;
  a {
    color: #000000;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;

const StyledImage = styled.img`
  padding: 4px;
  border: 1px solid #d0d0d0;
  cursor: pointer;
`;

export const SectionRoot = styled(Box)`
  border-top: 1px solid ${themeGet("colors.grays.0")};
`;

export const SectionItem = ({
  text,
  label,
}: {
  text: string | null;
  label: MessageDescriptor;
}) => {
  const intl = useIntl();
  return (
    <Box marginBottom={3}>
      <SectionLabel mb="20px">{intl.formatMessage(label)}</SectionLabel>
      <Linkify options={{ target: { url: "_blank" } }}>
        <SectionDescription>{text}</SectionDescription>
      </Linkify>
    </Box>
  );
};

export const ImagePreview = ({
  label,
  base64String,
  height = "90px",
  width = "160px",
  onClick,
}: {
  label: MessageDescriptor;
  base64String: string;
  height?: string;
  width?: string;
  onClick: () => void;
}) => {
  const intl = useIntl();

  return (
    <Box flexDirection="row" marginY="8px">
      <ImageLabel>{intl.formatMessage(label)}</ImageLabel>
      <StyledImage
        src={base64String}
        height={height}
        width={width}
        onClick={onClick}
      />
    </Box>
  );
};
