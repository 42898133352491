import React, { memo } from "react";
import { Modal, Box, Text, Label } from "@cyberalarm/ui";
import styled from "styled-components";
import { useToggle } from "@cyberalarm/common";

interface SiteImageViewerProps {
  result: CheckResult | TestResult;
  domain: BuiltDomain;
  close: () => void;
  isOpen: boolean;
  showAlikeImage: boolean;
}

const StyledImage = styled.img`
  padding: 4px;
  border: 1px solid #d0d0d0;
  margin-top: 12px;
`;
const StyledText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;

export const SiteImageViewer = memo(
         ({
           result,
           domain,
           close,
           isOpen,
           showAlikeImage,
         }: SiteImageViewerProps) => {
           const [showAlike, toggleShowAlike] = useToggle(showAlikeImage);

           const alikeImage = result.additionalInfo?.find(
             (e) => e.name === "alike_site_image"
           )?.content;

           const domainImage = domain.metadata?.data.find(
             (e) => e.type === "domain_site_image"
           )?.attributes.content;

           return (
             <Modal
               isOpen={isOpen}
               close={close}
               maxWidth="900px"
               width={{ xs: "100%", md: "70%" }}
               showCloseIcon={false}
             >
               <Box
                 p={2}
                 width="90vw"
                 backgroundColor="white"
                 borderRadius="10px"
               >
                 <Box flexDirection="row" justifyContent="flex-end"></Box>
                 <Box margin="auto" padding="12px">
                   <Label>
                     <Text fontSize="24px" fontFamily="title">
                       {showAlike
                         ? `Screenshot of ${result.domain.name}`
                         : `Screenshot of original website ${domain.name}`}
                     </Text>
                   </Label>
                 </Box>
                 <Box justifyContent="space-between" flexDirection="row">
                   <StyledText onClick={toggleShowAlike}>
                     {showAlike
                       ? `Compare to original website ${domain.name}`
                       : `Screenshot of ${result.domain.name}`}
                   </StyledText>
                   <StyledText onClick={close}>
                     Back to Result details
                   </StyledText>
                 </Box>
                 {showAlike && alikeImage && (
                   <Box flex={1}>
                     <StyledImage src={alikeImage} />
                   </Box>
                 )}
                 {showAlike && !alikeImage && (
                   <Box flex={1}>
                     <Text padding="50px" fontSize="28px">
                       Alike domain website screenshot not available yet!
                     </Text>
                   </Box>
                 )}
                 {!showAlike && domainImage && (
                   <Box flex={1}>
                     <StyledImage src={domainImage} />
                   </Box>
                 )}
                 {!showAlike && !domainImage && (
                   <Box flex={1}>
                     <Text padding="50px" fontSize="28px">
                       Original website screenshot not available yet!
                     </Text>
                   </Box>
                 )}
               </Box>
             </Modal>
           );
         }
       );

SiteImageViewer.displayName = "SiteImageViewer";
