import React, { useContext, memo } from "react";

import { DetailsContext } from "./context";

import { CheckDetails } from "ui";
import { useDomainCheckResults } from "hooks";
import { useDomainDetails } from "../dashboard/hooks";

export const ModalDetails = memo(() => {
  const { close, isOpen, id } = useContext(DetailsContext);
  const details = useDomainCheckResults({ id });
  const domain = useDomainDetails();

  return (
    <CheckDetails
      details={details}
      domain={domain}
      close={close}
      isOpen={isOpen}
    />
  );
});

ModalDetails.displayName = "ModalDetails";
